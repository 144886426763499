<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box style="background-color: rgba(255, 255, 255, 0.6)">
        <template v-slot:preview>
          <div class="row py-5 px-5 mx-0" id="pdf-holder" v-if="yarn">
            <div class="col-12 mb-5">
              <p class="text-center text-2xl font-weight-bolder">SAMBTEX {{ $t('sample.press_instruction') }}</p>
            </div>
            <div class="col-12 mb-4">
                <span
                    class="d-inline-block mr-4 font-weight-bolder text-xl">{{ $t('sample.supplier_company') }} : </span>
              <span class="font-weight-light text-xl d-inline-block"> Limonist Tekstil LTD ŞTİ</span>
            </div>
            <div class="col-12 row mx-0">
              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 row px-0">
                <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{ $t('sample.order_date') }} :</span>
                </div>
                <div class="col-7 mb-2"><span class="text-lg">13.06.2021</span></div>

                <div class="col-5 mb-2"><span
                    class="font-weight-bolder text-lg">{{ $t('sample.dispatch_location') }} :</span></div>
                <div class="col-7 mb-2"><span class="text-lg">Beşiktaş Teksil</span></div>

                <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{
                    $t('sample.raw_fabric_flow_date')
                  }} :</span>
                </div>
                <div class="col-7 mb-2"><span class="text-lg">9.06.2021</span></div>

                <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{
                    $t('sample.painted_flow_date')
                  }} :</span>
                </div>
                <div class="col-7 mb-2"><span class="text-lg">9.06.2021</span></div>
              </div>

              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 row px-0">
                <div class="col-5 mb-2"><span
                    class="font-weight-bolder text-lg">{{ $t('sample.customer_name') }} :</span></div>
                <div class="col-7 mb-2"><span class="text-lg">River Island</span></div>

                <div class="col-5 mb-2"><span
                    class="font-weight-bolder text-lg">{{ $t('sample.collection_no') }} :</span></div>
                <div class="col-7 mb-2"><span class="text-lg">KOL01504</span></div>

                <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{
                    $t('sample.raw_fabric_end_date')
                  }} :</span></div>
                <div class="col-7 mb-2"><span class="text-lg">12.06.2021</span></div>

                <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{
                    $t('sample.painted_end_date')
                  }} :</span></div>
                <div class="col-7 mb-2"><span class="text-lg">12.06.2021</span></div>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-start navy-blue-border-1px mx-0 w-100 px-5 py-3">
              <div class="">
                <qr-code :value="'this is text'" :size="150"></qr-code>
              </div>
              <div class="px-16 row">
                <div class="col-6 row px-0 d-flex items-between h-100">
                  <div class="col-5 mb-2"><span
                      class="font-weight-bolder text-lg">{{ $t('sample.fabric_type') }} :</span></div>
                  <div class="col-7 mb-2"><span class="text-lg">Süprem</span></div>

                  <div class="col-5 mb-2"><span
                      class="font-weight-bolder text-lg">{{ $t('sample.fabric_color') }} :</span></div>
                  <div class="col-7 mb-2"><span class="text-lg">Pembe</span></div>

                  <div class="col-5 mb-2"><span
                      class="font-weight-bolder text-lg">{{ $t('sample.weight') }} :</span></div>
                  <div class="col-7 mb-2"><span class="text-lg">1500 gr</span></div>

                  <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{ $t('sample.width') }} :</span>
                  </div>
                  <div class="col-7 mb-2"><span class="text-lg">100 cm</span></div>
                  <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{ $t('sample.press_kind') }} :</span>
                  </div>
                  <div class="col-7 mb-2"><span class="text-lg">Pigment Baskı</span></div>
                </div>
                <div class="col-6 row px-0">
                  <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{ $t('sample.amount') }} :</span>
                  </div>
                  <div class="col-7 mb-2"><span class="text-lg">150</span></div>

                  <div class="col-5 mb-2"><span class="font-weight-bolder text-lg">{{ $t('sample.price') }} :</span>
                  </div>
                  <div class="col-7 mb-2"><span class="text-lg">12 TL</span></div>

                  <div class="col-5 mb-2"><span
                      class="font-weight-bolder text-lg">{{ $t('sample.proses') }} :</span></div>
                  <div class="col-7 mb-2"><span class="text-lg">Samfor</span></div>

                  <div class="col-5 mb-2"><span
                      class="font-weight-bolder text-lg">{{ $t('sample.description') }} :</span></div>
                  <div class="col-7 mb-2"><span class="text-lg">Baskı tonlaması Canlı Olmalı</span></div>
                </div>
              </div>
            </div>
            <div class="col-12 my-4 text-lg px-0">
              <p>- INDITEX CTW ve MRSL standartlarına uygun olarak üretilmelidir.</p>
              <p>- Kumaşlar da sipariş kilosunun %40’ı ham kontrolünün yapılı, günlük olarak kontrol çizelgesi
                SAMBTEX’e faxlanacaktır.</p>
              <p>- İplik lot farkları irs. Üzerinde ayrı ayrı belirtilecek olup, kesinlikle karışmaması
                gerekmektedir.</p>
              <p>- Siparişe başlamadan 5 MT kumaş örülup ham kumaşta iplik ve örmeden kaynaklı sorunların tespitinin
                yapılması ve bu tespit doğrultusunuda çıkan sonuç müspet ise örgüye devam deilecektir.</p>
              <p>- Kumaş ham örme tedarikçi firma yetkilisi ve kaşe onayı olmadan hiç bir sözleşme ve talimatın işleme
                alınmaması aksi halde işleme alınan talimattan dolayı çıkabilecek sorunlar ve cezai işlemler alıcı
                firma yetkilisine yansıtılacaktır.</p>
              <p>- Fason veya satış ham kumaşlarımızda ilk sevkiyat 2 gün içinde, son sevkiyatın yukarıda belirtilen
                ham son geliş tarihinde sevk edilmelidir. Verilen terminlerde gelmeyen kumaşları Sambtex’in almaya
                veyahut indirimli alma hakkına sahiptir.</p>
              <p>- Satış ham kumaşlarda verilen termin bilgilerinden geç gelen kumaşlar siparişi geçen firmanın
                (Sambtex) kumaşı almam veyahut indirim talep etme hakkı bulunmaktadır.</p>
              <p>- Kontrol raporlarında Sambtex çalışanlarının imzası olmadan sevki yapılan kumaşlardan çıkabilecek
                sorunlarda, sevki sağlayan firma sorumludu.</p>
              <p>- İmalatçı tedarikçi firma iş bu sipariş formu içeriğinin tamamına uygun olarak üretim ve
                tedariklerini yerine getirmeyi kabuk ve taahhüt eder.</p>
              <p>- İş bu sipariş formuna 2 iş günü içerinde yazılı olarak iletmediği olarak iletmediği süreçte tüm
                içeriği kabuk edilmiş sayılır.</p>
            </div>

            <div class="col-12 d-flex justify-content-end">
              <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 navy-blue-border-1px h-150px">
                <p class="text-center text-2xl mt-4 font-weight-bolder">{{ $t('sample.approve_signature') }}</p>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import QrCode from "qrcode.vue";
import {mapGetters, mapMutations} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import Vue from 'vue';
import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

export default {
  name: "PressPDF",
  components: {
    DashboardBox,
    QrCode
  },
  computed: {
    ...mapGetters({}),
  },
  data() {
    return {
      yarn: true
    };
  },
  methods: {
    ...mapMutations({}),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Vue Bootstrap", route: "alert"},
      {title: "Modal"}
    ]);
    let self = this;
    setTimeout(function(){
      self.downloadPdf('pdf-holder', 'press')
    }, 1000);
  }
}
</script>

<style scoped>

</style>